import React, { useEffect, useRef, useState } from "react";
import {
  motion,
  useAnimation,
  useScroll,
  useTransform,
  AnimatePresence,
} from "framer-motion";
import { useInView } from "react-intersection-observer";
import Navbar from "./Navbar";
import Card from "./Card";
import Stripe from "../animation/Stripe";
import Reveal from "../animation/Stripe";
import TypingAnimation from "../animation/TypingAnimation";
import profilePic from "../assets/profile-pic2.png";
import Slider from "./Slider";
import DraggableCircles from "./DraggableCircles";

import Lottie from "lottie-react";
import helloAnimation from "../assets/helloAnimation.json";
import emailSvg from "../assets/contact.svg";
import Footer from "./Footer";

const Home = () => {
  const containerRef = useRef(null);
  const controls = useAnimation();
  const { ref, inView } = useInView();
  const speed = 1 / 15;
  const { scrollY } = useScroll();
  const y = useTransform(scrollY, (value) => -value * speed);
  const yimg = useTransform(scrollY, (value) => {
    return value < 320 ? 0 : ((-value + 320) * 1) / 2;
  });

  React.useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    }
  }, [controls, inView]);

  // useEffect(() => {
  //   // Access Lottie's DOM element and change its color
  //   const animationContainer = containerRef.current;
  //   animationContainer.style.color = 'yellow';
  // }, []);

  return (
    <>
      <div className="">
        {/* Placeholder for scrolling */}

        <motion.div
          style={{ y: y }}
          ref={ref}
          className="w-full flex items-center fixed top-36"
        >
          <div className=" flex flex-col md:flex-row justify-between text-white px-4 md:px-12 w-full">
            <div className="flex flex-col justify-start items-start">
              <div className="text-4xl md:text-6xl py-2 tracking-widest">
                <Stripe
                  delay="0.5"
                  className="text-white font-bold"
                  background="yellow"
                >
                  Hi,
                </Stripe>
                <Stripe
                  delay="0.5"
                  className="text-white font-bold"
                  background="yellow"
                >
                  I'M VAIBHAV
                </Stripe>
                <Stripe
                  delay="0.5"
                  className="text-yellow-500 font-bold md:text-5xl"
                  background="white"
                >
                  WEB DEVELOPER
                </Stripe>
              </div>
              <div className="my-2 border-t border-yellow-500 w-12"></div>
              <div className="py-2">
                <div className="flex py-1">
                  <a href="https://www.linkedin.com/in/vaibhav-patil-16260821a/" className="border-r pr-3">
                    Linkedin
                  </a>
                  <a href="https://github.com/Vaibhavpatil99" className="pl-3">
                    Github
                  </a>
                </div>
                <div className="flex ">
                  <a href="" className="border-r pr-2">
                    Twitter
                  </a>
                  <a href="https://www.instagram.com/vaibhav_patil2999/" className="pl-2">
                    Instagram
                  </a>
                </div>
              </div>
              <a
                href="https://drive.google.com/file/d/1GaQU_mkxA4o09kS2kWcmM2-7tz027cm3/view?usp=sharing" target="_blank"
                className="border border-yellow-500 text-center text-yellow-500 text-xl font-bold px-6 py-2 my-2 hover:bg-yellow-500 hover:text-black duration-300"
              >
                VIEW RESUME
              </a>
            </div>
            <div>
              {/* <motion.img
                style={{ y: yimg }}
                src={helloAnimation}
                alt="Inner"
                className=" relative object-contain h-inherit h-72 "
              /> */}

              {/* <Lottie
                animationData={helloAnimation}
                loop={true}
                autoplay={true}
                className=" relative object-contain h-inherit h-full "
              /> */}

              <div className="">
                <Lottie
                  animationData={helloAnimation}
                  loop={true}
                  autoplay={true}
                  className=" "
                />
              </div>
            </div>
            <div className="hidden md:block w-full md:w-[25vw] ">
              <p className="text-yellow-500 font-semibold text-2xl mb-6 ">
                INTRODUCTION
              </p>
              <Slider />
            </div>
          </div>
        </motion.div>

        <div className=" py-12 z-1000 bg-gradient-to-r from-zinc-900 from-0% via-zinc-800 via-50% to-zinc-900 relative top-[100vh]">
          <div className="flex md:hidden flex-col justify-center items-center py-12">
            <div className=" w-full text-white px-4">
              <p className="text-yellow-500 text-center font-semibold text-2xl mb-6 ">
                INTRODUCTION
              </p>
              <Slider />
            </div>
          </div>

          <div className="flex flex-col justify-center items-center py-12">
            <div className="flex flex-col justify-center md:w-1/2 items-center text-center gap-3 text-white ">
              <h2 className="text-2xl font-bold">What I Am Great At</h2>
              <p>
                I excel in both frontend and backend development, boasting
                proficiency in a diverse range of technologies. On the frontend,
                I leverage the power of React.js alongside JavaScript, HTML, and
                CSS to craft dynamic and visually appealing user interfaces. My
                mastery extends to utilizing Tailwind CSS for efficient styling
                and layout design. In backend development, I harness the
                versatility of Python and Django to architect robust and
                scalable web applications. Additionally, my expertise
                encompasses database management, with a strong command of MySQL.
                My skill set amalgamates seamlessly to deliver comprehensive and
                seamless solutions, ensuring optimal performance and user
                experience across the entirety of the development process.
              </p>
            </div>
          </div>

          <div className="flex justify-center items-center my-6">
            <DraggableCircles />
          </div>

          <div className="flex flex-col justify-center items-center my-12">
            <div className="flex flex-col justify-center items-center py-12">
              <div className="flex flex-col justify-center md:w-1/2 items-center text-center gap-3 text-white ">
                <h2 className="text-2xl font-bold">WORK EXPERIENCE</h2>
              </div>
            </div>

            <div className=" flex flex-col justify-center items-center md:w-[70vw]">
              <Card delay={0.1} className="">
                <div className=" bg-zinc-800 shadow-lg m-4 md:w-full rounded p-4">
                  <h2 className="text-2xl font-semibold text-yellow-500 my-2 text-center">
                    BluCognition Private Limited.
                  </h2>
                  <div className="mx-2 text-gray-300">
                    <ul className="list-disc">
                      <li className="">
                        Throughout my 6-month internship as a Software Developer
                        at BluCognition, I actively contributed to the
                        enhancement of features and resolution of bugs on both
                        frontend and backend utilizing technologies such as
                        JavaScript, Python, ReactJS and Django . I also
                        independently managed a project within BluCognition's
                        portfolio, showcasing my ability to take on project
                        responsibilities effectively.
                      </li>
                      <li className="">
                        Successfully implemented and integrated new features
                        into BluCognition's existing web applications, enhancing
                        user experience and functionality
                      </li>
                      <li>
                        Identified and resolved critical bugs in a timely
                        manner, ensuring the smooth operation and reliability of
                        BluCognition's software products during my internship.
                      </li>
                    </ul>
                  </div>
                </div>
              </Card>
            </div>
          </div>

          <div className="flex flex-col justify-center items-center my-12" id="projectsDiv">
            <div className="flex flex-col justify-center items-center py-12">
              <div className="flex flex-col justify-center md:w-1/2 items-center text-center gap-3 text-white ">
                <h2 className="text-2xl font-bold">PROJECTS</h2>
                
              </div>
            </div>

            <div className=" flex flex-col justify-center items-center md:w-[70vw]">
              <Card delay={0.1} className="my-8">
                <div className=" bg-zinc-800 m-4 shadow-lg md:w-full rounded p-4">
                  <h2 className="text-2xl font-semibold text-yellow-500 my-2 ">
                    Arun Art Exports
                  </h2>
                  <div className="mx-2">
                    <p className="text-gray-300">
                      I have actively participated as a Developer in a dynamic
                      project focused on developing and deploying a
                      comprehensive web application. This experience has
                      afforded me the valuable opportunity to make substantial
                      contributions to both the frontend and backend aspects of
                      the application, showcasing my proficiency across multiple
                      technical domains.
                    </p>
                    <p className="text-gray-300">
                      Led frontend and backend development for a dynamic web
                      application using JavaScript, Python, ReactJS, Django,
                      HTML, CSS, Bootstrap and MySQL.
                    </p>
                    <p className="text-gray-300">
                      Successfully deployed the application on cPanel, ensuring
                      high availability and optimal performance.
                    </p>
                    <a
                      className="text-white font-light underline "
                      href="https://www.arunartexports.com/"
                    >
                      www.arunartexports.com
                    </a>
                  </div>
                </div>
              </Card>

              <Card delay={0.1} className="my-8">
                <div className=" bg-zinc-800 m-4 shadow-lg md:w-full rounded p-4">
                  <h2 className="text-2xl font-semibold text-yellow-500 my-2 ">
                    Questprotocol
                  </h2>
                  <div className="px-6 text-gray-300">
                    <ul className="list-disc">
                      <li className="">
                        Spearheaded the development of responsive web pages,
                        seamlessly blending cutting-edge React technology with
                        intuitive design principles.
                      </li>
                      <li className="">
                        Applied expertise to meticulously identify and resolve
                        bugs, ensuring optimal user experience and
                        functionality.
                      </li>
                      <li className="">
                        Tailored sleek and user-friendly interfaces, exceeding
                        client expectations and enhancing brand identity
                      </li>
                      <li className="">
                        Collaborated closely with clients to translate their
                        visions into polished digital realities, delivering
                        exceptional results on time and within budget.
                      </li>
                      <li className="">
                        Enhanced existing pages through expert troubleshooting
                        and fine-tuning, maximizing performance and usability
                      </li>
                    </ul>
                  </div>
                </div>
              </Card>

              <Card delay={0.1} className="my-8">
                <div className=" bg-zinc-800 m-4 shadow-lg md:w-full rounded p-4">
                  <h2 className="text-2xl font-semibold text-yellow-500 my-2 ">
                    Medlife
                  </h2>
                  <div className="mx-2">
                    <p className="text-gray-300">
                      The main task of the project to convert HTML pages to
                      React Project. and creating lots of pages from given figma
                      design and all the pages has to be responsive
                    </p>
                  </div>
                </div>
              </Card>

              <Card delay={0.1} className="my-8">
                <div className=" bg-zinc-800 m-4 shadow-lg md:w-full rounded p-4">
                  <h2 className="text-2xl font-semibold text-yellow-500 my-2 ">
                    Samsung
                  </h2>
                  <div className="mx-2">
                    <p className="text-gray-300">
                      This self-created project represents a significant
                      milestone in my pursuit of learning goals, where I applied
                      my expertise in React JS to develop a comprehensive
                      solution. To develop the Front-end of the website i have
                      used React JS and for the Back-end part i have used
                      Express JS and MongoDB for the Database. Github Link :-
                    </p>
                    <a
                      className="text-white font-light underline "
                      href="https://github.com/Vaibhavpatil99/tech-e-commerce-websites.git"
                    >
                      https://github.com/Vaibhavpatil99/tech-e-commerce-websites.git
                    </a>
                  </div>
                </div>
              </Card>
            </div>
          </div>

          <div className="flex flex-col md:flex-row bg-zinc-800 px-3 md:px-12 mt-12 mx-4 shadow-lg" id="contactMeDiv">
            <div className="hidden md:block w-1/2 px-16">
              <img className="" src={emailSvg} alt="" />
            </div>
            <div className="flex flex-col justify-evenly md:w-1/2 md:px-16 py-6">
              <h2 className="text-xl md:text-4xl text-yellow-500 font-bold">
                CONNECT WITH ME
              </h2>
              <p className=" text-zinc-300">
                🚀 Ready to bring your ideas to life? Let's collaborate! Drop me
                a message and let's make your project a success. Contact me now!
                📩
              </p>
              <form className=" text-xl text-white" action="">
                <div className="flex flex-col my-4">
                  <label>NAME</label>
                  <input
                    type="text"
                    className=" bg-zinc-900 p-2 outline-none"
                  />
                </div>
                <div className="flex flex-col my-4">
                  <label>EMAIL</label>
                  <input
                    type="Email"
                    className=" bg-zinc-900 p-2 outline-none"
                  />
                </div>
                <div className="flex flex-col my-4">
                  <label>MESSAGE</label>
                  <textarea className=" bg-zinc-900 p-2 outline-none" />
                </div>
                <button className="border border-yellow-500 text-center text-yellow-500 text-xl font-bold px-6 py-2 my-2 hover:bg-yellow-500 hover:text-black duration-300">
                  SUBMIT
                </button>
              </form>
            </div>
          </div>
          <div>
            {/* <Footer /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
