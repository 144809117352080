import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const TypingAnimation = ({ children, isActive, className }) => {
  const [text, setText] = useState('');

  useEffect(() => {
    let index = 0;

    const typingInterval = setInterval(() => {
      const message = children;
      if (isActive && index <= message.length) {
        setText(message.slice(0, index));
        index++;
      } else {
        clearInterval(typingInterval);
      }
    }, 100);

    return () => clearInterval(typingInterval);
  }, [isActive, children]);

  return (
    <div>
      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className={className}
      >
        {text}
      </motion.p>
    </div>
  );
};

export default TypingAnimation;
