import logo from './logo.svg';
import { motion, useAnimation } from "framer-motion";
// import './App.css';
import Home from './Components/Home';
import Navbar from './Components/Navbar';

function App() {
  return (
    <>
    <div  className="bg-zinc-900">
    <Navbar />
    <Home />
    </div>
    </>
  );
}

export default App;
