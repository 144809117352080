import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from "framer-motion";

// const icon = {
//   hidden: {
//     pathLength: 0,
//     fill: "rgba(255, 255, 255, 0)",
//   },
//   visible: {
//     pathLength: 1,
//     fill: "rgba(255, 255, 255, 1)",
//   },
// };
const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
    <nav className={`transition-all duration-250 flex justify-between items-center px-12 py-6 text-yellow-500  ${isScrolled ? 'fixed top-0 left-0 right-0 bg-black shadow-lg z-10' : ''}`}>
      {/* <nav className="flex justify-between items-center px-12 py-6 text-yellow-500 "> */}
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          fill-rule="evenodd"
          clip-rule="evenodd"
          image-rendering="optimizeQuality"
          shape-rendering="geometricPrecision"
          text-rendering="geometricPrecision"
          viewBox="0 0 512000 512000"
          id="v-alphabet"
        >
          <motion.path
            //   d="M0 100V0l50 50 50-50v100L75 75l-25 25-25-25z"
            variants={icon}
            initial="hidden"
            animate="visible"
            fill="#2b2a29"
            d="M509392 52409H352417l-93229 298813h-11953L157193 52409H2608l149008 407182h215941z"
          ></motion.path>
        </svg> */}
        <motion.a
          whileHover={{ scale: 1.2 }}
          onHoverStart={(e) => {}}
          onHoverEnd={(e) => {}}
        >
          <h2 className=" text-4xl font-bold">VP</h2>
        </motion.a>
        
        <div className="flex gap-5">
          <a href='#projectsDiv'>PROJECTS</a>
          <a href='#contactMeDiv'>CONTACT</a>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
