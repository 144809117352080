import React from 'react';
import { motion } from 'framer-motion';
import htmllogo from '../assets/html.jpg'
import csslogo from '../assets/css.webp'
import jslogo from '../assets/js.png'
import reactlogo from '../assets/react.webp'
import pythonlogo from '../assets/python5.svg'
import djangologo from '../assets/djjgo.png'
import tailwindlogo from '../assets/tailwind.png'
import githublogo from '../assets/github.png'
const DraggableCircles = () => {
  return (
      <div className='bg-zinc-800 shadow-xl' style={{ position: 'relative', width: '800px', height: '400px', overflow: 'hidden' }}>
        
        <motion.div
           style={{ top:100, left: 50, width: '100px', height: '100px', borderRadius: '50%', position: 'absolute', backgroundImage: `url(${htmllogo})`, backgroundSize: 'cover', }}
           drag
           dragConstraints={{ left: -50, top: -100, right: 650, bottom: 200 }} // Adjust constraints as per your requirement
           
        />
        
      <motion.div
        style={{ top:100, left: 250, width: '100px', height: '100px', borderRadius: '50%', position: 'absolute', backgroundImage: `url(${csslogo})`, backgroundSize: 'cover', }}
        drag
        dragConstraints={{ left: -250, top: -100, right: 450, bottom: 200 }} // Adjust constraints as per your requirement
        
      />
     
      <motion.div
        style={{ top:100, left: 450, width: '100px', height: '100px', borderRadius: '50%', position: 'absolute', backgroundImage: `url(${jslogo})`, backgroundSize: 'cover', }}
        drag
        dragConstraints={{ left: -450, top: -100, right: 250, bottom: 200 }} // Adjust constraints as per your requirement
        
      />
     
     
      <motion.div
        style={{ top:100, left: 650, width: '100px', height: '100px', borderRadius: '50%', position: 'absolute', backgroundImage: `url(${reactlogo})`, backgroundSize: 'cover', }}
        drag
        dragConstraints={{ left: -650, top: -100, right: 50, bottom: 200 }} // Adjust constraints as per your requirement
        
      />
     
      <motion.div
        style={{ top:200, left: 50, width: '100px', height: '100px', borderRadius: '50%', position: 'absolute', backgroundImage: `url(${pythonlogo})`, backgroundSize: 'cover', }}
        drag
        dragConstraints={{ left: -50, top: -200, right: 650, bottom: 100 }} // Adjust constraints as per your requirement
        
      />
     
      <motion.div
        style={{ top:200, left: 250, width: '100px', height: '100px', borderRadius: '50%', position: 'absolute', backgroundImage: `url(${djangologo})`, backgroundSize: 'cover', }}
        drag
        dragConstraints={{ left: -250, top: -200, right: 450, bottom: 100 }} // Adjust constraints as per your requirement
        
      />
     
     
      <motion.div
        style={{ top:200, left: 450, width: '100px', height: '100px', borderRadius: '50%', position: 'absolute', backgroundImage: `url(${tailwindlogo})`, backgroundSize: 'cover', }}
        drag
        dragConstraints={{ left: -450, top: -200, right: 250, bottom: 100 }} // Adjust constraints as per your requirement
        
      />
     
     
      <motion.div
        style={{ top:200, left: 650, width: '100px', height: '100px', borderRadius: '50%', position: 'absolute', backgroundImage: `url(${githublogo})`, backgroundSize: 'cover', }}
        drag
        dragConstraints={{ left: -650, top: -200, right: 50, bottom: 100 }} // Adjust constraints as per your requirement
        
      />
      
     
    </div>

    
  );
}

export default DraggableCircles;