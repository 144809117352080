import React from 'react'
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Card = ({ children, className,delay,duration }) => {
    const controls = useAnimation();
    const { ref, inView } = useInView();
  
    React.useEffect(() => {
      if (inView) {
        controls.start({ opacity: 1, y: 0 });
      }
    }, [controls, inView]);
  
    return (
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 50 }}
        animate={controls}
        transition={{ duration: 0.5, delay }}
        className={className}
        style={{
          // width: 200,
          // height: 300,
          // backgroundColor: "lightblue",
          // margin: 10,
          // borderRadius: 10,
        //   boxShadow: "10px 10px 10px 10px black",
        }}
      >
        {children}
      </motion.div>
    );
  };
  

export default Card