import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import TypingAnimation from "../animation/TypingAnimation";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

export default function Slider() {
  const swiperRef = useRef(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const handleSlideChange = () => {
    if (swiperRef.current) {
      setCurrentSlideIndex(swiperRef.current.swiper.realIndex);
    }
  };

  return (
    <>
      <Swiper
        ref={swiperRef}
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        // pagination={{
        //   clickable: true,
        // }}
        modules={[Autoplay]}
        onSlideChange={handleSlideChange}
        className="mySwiper"
      >
        <SwiperSlide>
          <TypingAnimation
            isActive={currentSlideIndex === 0}
            className="text-4xl my-3"
          >
            FRONT-END DEVELOPER
          </TypingAnimation>
          <p className="text-gray-300">
            With extensive experience in frontend development, I bring expertise
            in JavaScript, HTML, and CSS to create seamless user experiences. My
            proficiency extends to cutting-edge frameworks like React.js,
            enabling the construction of robust web applications. I also excel
            in design, using tools like Tailwind CSS for visually stunning
            layouts. Passionate about innovation, I'm committed to delivering
            high-quality solutions and driving impactful results in every
            project.
          </p>
        </SwiperSlide>
        <SwiperSlide>
          <TypingAnimation
            isActive={currentSlideIndex === 1}
            className="text-4xl my-3"
          >
            BACK-END DEVELOPER
          </TypingAnimation>
          <p className="text-gray-300">
            With a solid background in backend development, I have garnered
            expertise across various projects and internships. Proficient in
            Python and Django, I specialize in crafting scalable web
            applications. Leveraging Django's capabilities, I design robust
            database structures and RESTful APIs. Additionally, I excel in
            database management with MySQL, ensuring optimal performance and
            data integrity. Passionate about innovation, I am committed to
            delivering high-quality solutions that exceed expectations.
          </p>
        </SwiperSlide>
        <SwiperSlide>
          <TypingAnimation
            isActive={currentSlideIndex === 2}
            className="text-4xl my-3"
          >
            PYTHON DEVELOPER
          </TypingAnimation>
          <p className="text-gray-300">
            Proficient in Python, NumPy, and Pandas, I've developed multiple
            backend applications, utilizing these tools to manipulate and
            analyze data effectively. From building data pipelines to optimizing
            performance, my expertise in the Python ecosystem has been key. With
            a track record of delivering high-quality solutions, I'm committed
            to pushing boundaries and achieving impactful results in backend
            development.
          </p>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
